@use "../variables" as *;
@use "../mixins/breakpoint" as *;

$cta-padding: 20px;

.ctaList {
    padding: 0;
    list-style-type: none;
    flex-direction: column;
    display: flex;
    margin: 0;
    min-width: 100%;

    @include breakpoint(md) {
        margin: 0;
        flex-wrap: wrap;
    }

    a {
        text-decoration: none;
    }

    & > li {
        padding: 0 0 $cta-padding;
        @include breakpoint(md) {
            // padding: 0 $cta-padding ($cta-padding * 2);
            padding: 0;
        }
        margin-bottom: 1rem;
    }
}

.ctaList--1-up {
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(md) {
        flex-direction: column;
        margin: 0;

        & > li {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }
}

.ctaList--2-up {
    @include breakpoint(md) {
        flex-direction: row;

        & > li {
            padding-bottom: 0;
            max-width: 50%;
            flex: 1 1 48%;
            margin-right: 1em;
            background-color: rgba(234, 235, 236, .6);
            box-shadow: 2px 2px 3px rgba(0,0,0,.05);
            margin-bottom: 15px;
        }
    }
}

.ctaList--3-up {
    // this is used to both shrink and align
    // the content within this container
    $side-padding: $cta-padding * 3;

    & > li {
        // width: 100%;
        padding-bottom: 20px;
        background-color: rgba(234, 235, 236, .6);
        box-shadow: 2px 2px 3px rgba(0,0,0,.05);
        margin-bottom: 15px;
        flex: 1 1 97%;
        margin: 0 1.5% 1rem 1.5%!important;
    }

    .page__industriesRelated .ctaList--3-up > li, .page__industriesRelated .ctaList--2-up > li {
        box-shadow: none;
        background-color: transparent;
    }

    @include breakpoint(md) {
        flex-direction: row;
        justify-content: space-between;

        & > li {
            flex: 1 1 47%;
        }
    }

    @include breakpoint(lg) {
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;

        & > li {
          flex: 1 1 30%;
          max-width: 30%;
            // width: 30%;
            // padding-right: 0;
            // padding-left: 0;
            // max-width: 30%;
            // flex: 1 1 30%;
            // margin: 0 1.5%;
        }
    }
}

.page--hasSidebar .page__content {
    .ctaList.ctaList--3-up {
        @media (min-width: $width-desktop-min) and (max-width: $width-standard-screen-min) {
            li {
                width: 46%;

                .button {
                    min-width: 225px;
                }
            }
        }

        @media (min-width: $width-standard-screen-min) {
            padding: 0 60px;

            li {
                padding-left: 0;
                padding-right: 15px;

                .button {
                    min-width: 225px;
                }
            }
        }
    }
}

.ctaList--4-up {
    @include breakpoint(sm) {
        flex-direction: column;

        & > li {
            width: 100%;
        }
    }

    @include breakpoint(md-lg) {
        flex-direction: row;

        & > li {
            width: 50%;
        }
    }

    @include breakpoint(lg) {
        & > li {
            width: 33.33%;
            max-width: 33.33%;
        }
    }

    @include breakpoint(xl) {
        & > li {
            width: 25%;
            max-width: 25%;
        }
    }
}

.ctaList--5-up {
    @include breakpoint(sm) {
        flex-direction: column;

        & > li {
            width: 100%;
        }
    }

    @include breakpoint(md) {
        flex-direction: row;

        & > li {
            width: 50%;
        }
    }

    @include breakpoint(md-lg) {
        & > li {
            width: 33.33%;
            max-width: 33.33%;
        }
    }

    @include breakpoint(lg) {
        & > li {
            width: 25%;
            max-width: 25%;
        }
    }

    @include breakpoint(xl) {
        & > li {
            width: 20%;
            max-width: 20%;
        }
    }
}

.ctaList--6-up {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;

    & > li {
        padding: 0 10px 20px;
        width: 50%;
    }

    @include breakpoint(md) {
        flex-direction: row;
        margin: 0 -30px;
        & > li {
            padding: 0 30px 40px;
            width: calc(100% / 3);
        }
    }

    @include breakpoint(md-lg) {
        & > li {
            width: 25%;
            flex-basis: 25%;
        }
    }

    @include breakpoint(lg) {
        & > li {
            width: 20%;
            flex-basis: 20%;
        }
    }

    @include breakpoint(xl) {
        & > li {
            width: calc(100% / 6);
            flex-basis: calc(100 / 6);
        }
    }
}

.ctaList--mobileScroll {
    @include breakpoint(sm) {
        flex-wrap: nowrap;
        display: flex;
        overflow: auto;
        flex-direction: row;
        margin-left: $wrap-padding-w * -1;
        margin-right: $wrap-padding-w * -1;

        scroll-snap-type: x mandatory;

        & > li {
            flex: 1 auto;
            min-width: 90%;
            scroll-snap-align: center;
            padding: 0 $wrap-padding-w;
        }
    }
}

.ctaList--liners {
    & > li {
        border-bottom: 1px solid rgba($color-jabil-light-grey, 0.4);
    }
}

.ctaList--text {
    margin-left: 0;
    margin-right: 0;

    & > li {
        width: 100%;
        padding: 0;
        border-top: 1px dashed rgba(255, 255, 255, 0.7);

        &:first-child {
            border-top-width: 0;
        }
    }

    a {
        padding: map-get($spacing, 1);
        background-position: 100%;
        background-size: 400%;
        transition: background 0.5s ease-out;
        background-image: linear-gradient(45deg, rgba(250, 250, 250, 0.2) 50%, transparent 50%);
        flex-wrap: nowrap;

        &:hover {
            background-position: 0;

            .icon {
                fill: currentColor;
                color: $color-white;
            }
        }
    }
}

.ctaList--noResults {
    &:before {
        content: "No Results.";
        color: $color-white;
        margin: 0 auto 3rem;
        font-size: 1.2rem;

        @include breakpoint(md) {
            margin: 0 1.75rem 3rem;
            font-size: 1.5rem;
        }
    }
}
