@use "../base";
@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@use "../modules/blogListing";
@use "../modules/colorTint";
@use "../modules/cta";
@use "../modules/ctaBand";
@use "../modules/ctaBar";
@use "../modules/ctaList";
@use "../modules/featuredBlog";
@use "../modules/image";
@use "../modules/page";
@use "../modules/pageHero";
@use "../modules/share";
@use "../modules/tabs";
@use "../modules/theme";
@use "../modules/title";
@use "../modules/titleHr";
@use "../modules/toolbar";
@use "../modules/columns";
@use "../modules/richText";
@use "../modules/sectionNav";
@use "../modules/video";
@use "../modules/blogPromo";
@use "../modules/parallaxedSection";

.blogMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .pardotForm--transparentBlack.pardotForm {
        background: $color-blue-transparent-75;
    }

    .pageHero--adjustHeroFade .pageHero__bg:before {
        display: none;
    }

    .pageHero__bg {
        box-shadow: 1px 3px 5px $color-black-transparent-16;
    }

    .pageHero__wrap {
        padding-top: 2em;
        padding-bottom: .5em;

        @media screen and (min-width: $width-tablet-min) {
            padding-top: 2em;
            padding-bottom: .5em;
        }

        @media screen and (min-width: $width-desktop-min) {
            padding-bottom: .5em;
        }
    }

    .pageHero__left {
        @media screen and (min-width: $width-desktop-min) {
            flex-basis: 50%;
        }
    }

    .pageHero__right {
        margin-top: 2em;

        @include breakpoint(md) {
            width: $width-main-content;
        }

        @media screen and (min-width: $width-desktop-min) {
            flex-basis: 35%;
            align-self: center;
            margin-top: 0;
        }
    }

    .pageHero__video {
        @media screen and (max-width: $width-tablet-max) {
            height: 100%;
            width: auto;
        }
    }
}

.blogPage {
    &.magnolia-editmode {
        .blogMain {
            display: block;
        }
    }

    main {
        margin-top: 0;
    }
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} {
        .blogFilters__link:hover,
        .blogFilters__link--active {
            @if isColorLight($value) {
                color: $color-black;
            }
            @if isColorDark($value) {
                color: $color-white;
            }

            border-color: $value;
        }
    }
}

.listItem__categoryList {
  display: flex;
  flex-wrap: wrap;

  > li {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
  }

}

.ctaList--hideDescriptions.ctaList--3-up {

  padding: 0 3rem;

  > li {
    padding: 0 1rem 1rem!important;
  }

}

.tabs__tab {
  background: $color-jabil-blue-redesign;
}
