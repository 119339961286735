@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.ctaBand {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    position: relative;
    overflow: hidden;

    > .wrap {
        position: relative;
        z-index: 4;
    }

    .title {
        font-weight: bold;

        @include breakpoint(md) {
            margin-bottom: 40px;
        }
    }

    .cta--overlay,
    .cta__image img {
        border: none;
    }

    &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background: linear-gradient(0deg,#001628,rgba(0,0,0,.3) 60%);
            opacity: .8;
        }
        

    &:before {
        background-color: $color-black-transparent-75;
        z-index: 2;
        opacity: 0.4;
    }

    @include forColors(
            $optOut: (
                "orange"
            )
        )
        using ($color, $value) {
        .colorTheme--#{$color} &:after {
            background: linear-gradient(0deg, rgba(darken($value, 12%), 1) 0%, rgba(0, 0, 0, 0.3) 60%);
        }

        .colorTheme--#{$color} &.ctaBand--blackGrayGradient:after {
            background: linear-gradient(180deg, rgba(darken($color-jabil-light-grey, 40%), 1) 0%, rgba(0, 0, 0, 0.3) 60%);
        }
    }
}

.ctaBand__bg {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.colorTint:after {
        background-color: rgba($color-black, 0.6);
    }
}

.page__wrap .ctaBand__bg {
    background-color: $color-jabil-dark-blue2;
    background-blend-mode: overlay;
    filter: brightness(.55);

}

.page__wrap .ctaBand__bg ~ .wrap .ctaList .button {
    color: $color-white;
}


.ctaBand:before,
.ctaBand:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.ctaBand:after {
    opacity: 0.8;
}

.page__wrap {
    .ctaBand .ctaBand__title {
        color: $color-white;
    }

    .cta__title {
        color: $color-white;
    }
}

.ctaBand .ctaBand__title {
    color: $color-jabil-dark-blue; 
    padding-bottom: .5em;
}

.page__industriesRelated {

    // .cta__content {
    //    background-color: rgba(234, 235, 236, .6);
    //    box-shadow: 2px 2px 3px rgba(0,0,0,.05);
    // }

    .cta__title {
        font-weight: 500;
        color: $color-jabil-dark-blue;
        padding-top: 0;
    }
}

.ctaBand--center {
    .ctaList {
        margin: 0 auto;
    }
}

.theme--light {
    &:before {
        background-color: $color-black;
    }
}

.page--backgroundWhite {
    .page__container {
        .theme--none.ctaBand {
            .cta__title,
            .ctaBand__title {
                color: $color-black;
            }
        }

        .theme--light {
            &.ctaBand {
                .cta__title,
                .ctaBand__title {
                    color: $color-black;
                }
                &::before {
                    background-color: $color-white;
                }
            }
        }
    }
}

.page__content .ctaBand {
    padding: 40px 20px;
}

.page__content .ctaBand.ctaBand--noPadding {
    padding: 0;
}