@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.toolbar__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 0.7em;
    margin-bottom: 0.7em;
    padding-bottom: 1.2em;
    width: 100%;

    @include breakpoint(md) {
        font-size: 1.2em;
        margin-top: 1em;
    }
}

.toolbar__title {
    color: $color-grey-cc;
    font-size: 1em;
    font-weight: bold;
    margin: 0;

    @include breakpoint(md) {
        font-size: 1.2em;
    }
}
