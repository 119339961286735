@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

$padAdjust: calc(#{$containerPad} * -2 * 0.3 + #{$containerPad});

.pageHero {
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $font-lato;
    position: relative;
    width: 100%;
    box-shadow: 1px 3px 5px $color-black-transparent-16;

    // @include breakpoint(xl) {
    //     min-height: 38em;
    // }
    @include breakpoint(lg) {
      padding-top: 5rem;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0);
    }

    .video__wrapper {
        border: 3px solid $color-white;
    }
}

.pageHero__bg {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    overflow: hidden;

    .video__container {
        margin-top: -1%;

        .videoCover__text {
            &:before {
                display: none;
            }
        }
    }
}

.pageHero__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: $width-standard-screen-max) {
        width: auto;
        height: 100%;
    }
}

.pageHero__wrap {
    padding-top: 4em;
    padding-bottom: 4em;
    height: inherit;

    @include breakpoint(md-lg) {
        padding-top: 5.5rem;
        padding-bottom: 3em;
    }
}

.pageHero__wrap,
.pageHero__right {
    position: relative;
    z-index: 5;

    .video__container {
        border: none;
    }
}

.pageHero__columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(lg) {
        flex-direction: row;
        align-items: center;
    }
}

.pageHero__left {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    width: 50%;

    @include breakpoint(lg) {
        min-width: calc(50% - #{$padAdjust});
    }
}

.articleMain {
    .pageHero--shortenHeight .pageHero__left {
        width: 50%;

        .pageHero__title>h1 {
            font-size: 2em;
        }
    }
}

@media only screen and (max-width: 900px) {
    .pageHero__left {
        width: calc(100% + 4em);
        margin: -1em -2em 6em -2em;
        padding: 0 2em;
        background: $color-jabil-darker-blue;


            .pageHero__title > h1 {
                font-size: 2em;
                padding: 22px 0;
            }

            .pageHero__subtitle > h2 {
                margin: 10px 0;
                font-size: 1.2em;
            }

    }
    .pageHero .pageHero__bg {
        background-position: right center;
        background-size: 150% auto;
        background-color: $color-jabil-darker-blue;
    }

}

.pageHero__right {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(#{$width-main-sidebar} + #{$padAdjust});

    > * {
        width: 100%;
        margin: 0 0 1rem 0;

        > * {
            width: 100%;
            margin: 0 0 1rem 0;
        }
    }

    @include breakpoint(lg) {
        max-width: calc(#{$width-main-sidebar} + #{$padAdjust});
        margin-left: 1.5rem;

        > * {
            margin: 2rem auto;
        }
    }

    .imageHero {
        max-height: 275px;
        width: auto;
    }
}

.pageHero__title,
.pageHero__subtitle {
    color: $color-white;
    text-align: left;
    flex-shrink: 0;
}

.pageHero__description {
    color: $color-white;
    flex-grow: 1;

    p {
        font-size: 1rem;
    }

    @include breakpoint(md) {
        p {
            font-size: 1.125rem;
        }
    }

    @include breakpoint(lg) {
        text-align: left;
    }
}

.pageHero__linkList {
    margin-bottom: 10px;

    ul {
        padding-inline-start: 32px;
    }

    li {
        text-decoration: none;
        list-style: none;
    }

    .heroLink {
        color: $color-white;
        text-decoration: none;
        font-size: 14px;
        line-height: 3.5rem;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active {
            color: $color-white;
        }

        &:before {
            content: "";
            border: solid $color-white;
            border-radius: 2px;
            left: 22px;
            margin-top: 23px;
            border-width: 0 3px 3px 0;
            position: absolute;
            display: inline-block;
            padding: 4px;
            transform: rotate(-45deg);
        }

        &:after {
            content: "";
            border-top: 3px solid $color-white;
            left: 18px;
            margin-top: 27px;
            position: absolute;
            display: inline-block;
            padding: 8px;
        }
    }

    @include breakpoint(md) {
        ul {
            padding-inline-start: 40px;
        }

        .heroLink {
            font-size: 22px;

            &:before {
                left: 40px;
                margin-top: 24px;
            }

            &:after {
                left: 36px;
                margin-top: 28px;
            }
        }
    }
}

.pageHero__buttons {
    align-items: center;
    padding-top: 1rem;
    flex-wrap: wrap;

    .share {
        display: none;
        flex-shrink: 0;
        margin-bottom: 2rem;
        margin-right: 1rem;
    }
}

.pageHero__homeButton {
    width: auto;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 8px;
    margin-bottom: 2rem;

    @include breakpoint(md) {
        flex-grow: 0;
        padding: $buttonPad;
    }
}

.pageHero__title > h1 {
    font-size: 1.7em;
    font-weight: 300;
    text-shadow: 1px 3px 5px $color-black-transparent-16;
    padding: 32px 0;
    margin-bottom: 10px;
    display: inline-block;
    text-align: left;

    @include breakpoint(md) {
        font-size: 2.5em;
    }

    @include breakpoint(xl) {
        font-size: 3em;
    }
}

.pageHero__subtitle > h2 {
    font-weight: 500;
    margin: 10px 0;
    font-size: 0.9em;

    @include breakpoint(md) {
        margin: 20px 0 20px;
        font-size: 1.3em;
    }

    @include breakpoint(xl) {
        font-size: 1.5em;
    }
}

.pageHero__description a:link,
.pageHero__description a:active,
.pageHero__description a:visited {
    color: $color-white;
    text-decoration: underline;
}

.pageHero__description > p {
    color: $color-white;
    margin-top: 15px;
    font-weight: 300;
    line-height: 1.5rem;
}

.pageHero__contentContainer {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    &.pageHero__contentContainer--reverse {
        flex-direction: column-reverse;
    }
}

.pageHero--shortenHeight {
    min-height: 25em;

    .pageHero__left {
        width: 100%;
    }
    .pageHero__right {
        @include breakpoint(md) {
            width: 0;
        }
    }
}

.pageHero--fadeOut .pageHero__bg {
    bottom: -150px;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media(max-width: $width-large-tablet-min) {
      bottom: 0;
      background-position: center center;
      background-size: cover;
    }
}

.pageHero--adjustHeroFade .pageHero__bg {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.pageHero--noGradient .pageHero__bg {
    &:before {
        content: none;
    }
}

.pageHero--center {
    .pageHero__subtitle,
    .pageHero__title,
    .pageHero__title > h1 {
        text-align: center;
    }
}

.pageHero--darkText {
    .pageHero__title,
    .pageHero__subtitle,
    .pageHero__contentContainer,
    .pageHero__description > p {
        color: $color-black;
        > * {
            text-shadow: none;
        }
    }

    .pageHero__description a:link,
    .pageHero__description a:active,
    .pageHero__description a:visited {
        color: $color-black;
    }

    .share__network .icon {
        opacity: 0.85;
    }
}

//investorBar padding
.hasEnabledInvestorBar {
    .pageHero,
    .pageAuthorHero {
        padding-top: 0;
        @include breakpoint(lg) {
            padding-top: 5.5rem;
        }
    }
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} .pageHero--darkText .share__network svg {
        fill: $value;
    }
}

.pageHero__bgSlide {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pageHero__bgSlide::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.pageHero__bgSlideImage {
    display: none;
    vertical-align: top;
    width: 100%;
    height: 100vh;
    transition: all ease-in-out 0.5s;
}

.pageHero__bgSlideImage--show {
    display: block;
}
