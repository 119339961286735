@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.featuredBlog {
    justify-content: center;
    padding: 0.5rem 0;
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;

    .button {
        margin-top: 2rem;
        background-color: $color-jabil-light-blue;
        border-color: $color-jabil-light-blue;
    }

    .titleHr:after {
        border-bottom-color: $color-jabil-light-blue;
    }

    @include breakpoint(lg) {
        padding: 0.5rem 0;
        .button {
            margin-top: 3rem;
        }
    }
}

.featuredBlog__wrap {
    display: flex;
    flex-direction: column;
    max-width: $width-content-wrap;
    margin: 0 auto;
    padding: 0 1rem;

    @include breakpoint(md) {
        padding: 0 2rem;
        flex-direction: row;
    }
}

.featuredBlog__content {
    padding: 0 0 3rem;
    @include breakpoint(lg) {
        padding: 3rem;
    }
}

.featuredBlog__image {
    img {
        width: 100%;
        margin: 2rem auto 1rem;
    }

    @include breakpoint(lg) {
        padding-right: 5rem;
    }
}

.featuredBlog__title {
    color: $color-white;
    padding-bottom: 1rem;

    @include breakpoint(lg) {
        padding-bottom: 2rem;
    }
}

.featuredBlog__date {
    display: block;
    color: $color-white;
    padding-bottom: 0.5rem;

    @include breakpoint(lg) {
        padding-top: 1rem;
    }
}

.featuredBlog__categories {
    padding-bottom: 2em;

    li {
        display: inline;
        color: $color-jabil-light-blue;

        &:after {
            content: ", ";
        }

        &:last-child:after {
            content: "";
        }
    }

    a {
        color: $color-jabil-light-blue;
        transition: all ease 0.2s;
        text-decoration: none;
    }

    a:hover {
        color: $color-jabil-blue;
    }
}

.featuredBlog__bg {
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: -1;

    &:after {
        filter: blur(4px);
    }
}
