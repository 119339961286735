@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.parallaxedSection__wrap {
    display: block;
    padding: 1% 7%;
    max-width: $width-content-wrap;

    @include breakpoint(lg) {
        padding: 2% 8%;
    }

    &:after,
    &:before {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    &:before {
        z-index: 1;
    }

    &:after {
        z-index: 2;
    }
}

.parallaxedSection {
    position: relative;
    overflow: hidden;

    @include breakpoint(lg) {
        border-top-width: 30px;
    }

    .parallaxedSection__wrap {
        &:after {
            opacity: 0.15;
        }
    }

    .parallaxedSection__cta li {
        background-color: $color-jabil-green;
    }
}

.parallaxedSection__background {
    position: absolute;
    top: -5%;
    left: 0;
    width: 100%;
    height: 120%;
    object-fit: cover;
    object-position: center;
    filter: blur(3px);
}

.parallaxedSection__textContainer,
.parallaxedSection__image {
    z-index: 3;
    position: relative;
}

.parallaxedSection__textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(md) {
        width: 65%;
    }

    * {
        color: $color-white;
    }
}

.parallaxedSection__heading {
    display: none;
    margin-bottom: 0;
    padding-bottom: 1.5rem;
    position: relative;
    z-index: 3;

    @include breakpoint(lg) {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
    }

    @include breakpoint(xl) {
        display: block;
    }

    &--mobile {
        display: block;

        @include breakpoint(xl) {
            display: none;
        }
    }
}

.parallaxedSection__title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: $color-white;

    a {
        text-decoration: none;
    }
}

.parallaxedSection__subtitle {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 1;

    @include breakpoint(md) {
        font-size: 1.25rem;
        margin-top: 1.25rem;
    }

    @include breakpoint(lg) {
        font-size: 1.5rem;
        margin-top: 1.5rem;
    }
}

.parallaxedSection__description p {
    font-size: 0.75rem;
}

.parallaxedSection__image {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    > * {
        flex-grow: 1;
    }

    @include breakpoint(md) {
        width: 30%;
        margin-bottom: 0;
    }
}

/* stylelint-disable */
.parallaxedSection__cta {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5rem -20px -20px -20px;

    @include breakpoint(md) {
        flex-direction: row;
    }

    @include breakpoint(lg) {
        margin-top: 2.5rem;
    }

    li {
        text-transform: uppercase;
        flex-basis: 20%;
        display: flex;
        align-items: center;
        margin: auto 20px 20px 20px;

        a {
            text-decoration: none;
            display: block;
            font-size: 0.8rem;
            padding: 0.8rem 0.5rem;
            text-align: center;
            width: 100%;
        }
    }
}

.video--noTitle .video__title,
.video--noSubtitle .video__subtitle {
    display: none;
}

@include forColors() using ($color, $value) {
    .parallaxedSection--#{$color} {
        .parallaxedSection__cta li {
            background-color: $value;
        }
    }
}

.blogPage,
.pillarPage,
.capabilitiesPage,
.sustainabilityPage {
    .parallaxedSection:nth-child(even) .parallaxedSection__shelf {
        flex-direction: row-reverse;
    }

    .parallaxedSection__wrap {
        padding: 7% 2rem;
        max-width: $width-content-wrap;
    }

    .parallaxedSection__wrap * {
        color: $color-white;
    }

    .parallaxedSection__shelf {
        z-index: 3;
        position: relative;

        @include breakpoint(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .parallaxedSection__title {
        font-size: 1.5rem;
        font-weight: 500;

        @include breakpoint(md) {
            font-size: 2rem;
        }

        @include breakpoint(md-lg) {
            font-size: 2.5rem;
        }
    }

    .parallaxedSection__description p {
        font-size: 1.125rem;
        font-weight: 300;

        @include breakpoint(sm) {
            font-size: 1rem;
        }
    }
}
/* stylelint-enable */
