@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.tabs__selects {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(lg) {
        flex-direction: row;
    }
}

.tabs__select {
    padding-right: 0;
    text-align: left;
    width: 100%;

    @include breakpoint(lg) {
        width: 25%;
        padding-right: 20px;
    }

    label {
        color: $color-white;
        display: block;
        padding-bottom: map-get($spacing, 1);
    }

    select {
        width: 100%;
        position: relative;
        border: 1px solid $color-jabil-dark-blue;
        -webkit-appearance: none;
        border-radius: 3px;
        color: $color-jabil-dark-blue;
        font-weight: 300;
        background-color: transparent;
        background-color: $color-white;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
        padding: 0.8em 3.2em 0.8em 1em;
        display: flex;
        height: 48px;

        background-image: url("../../src/img/icons/chevron-down-solid.svg");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: center;
        background-size: 20px;
    }
}
