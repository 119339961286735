@use "../variables" as *;

.blog-promo-wrapper {
    min-height: 340px;
    overflow: hidden;
    position: relative;
}

.blog-border-video {
    @media screen and (min-width: $width-tablet-min) {
        &:after {
            content: "";
            width: 95%;
            height: 94%;
            position: absolute;
            top: 1%;
            left: 3%;
            border: 1px solid #fff;
            z-index: -1;
        }
    }

    @media screen and (min-width: $width-desktop-min) {
        &:after {
            width: 74%;
            height: 92%;
            top: 2%;
            left: 13%;
        }
    }

    @media screen and (min-width: $width-standard-screen-min) {
        &:after {
            width: 80%;
            height: 92%;
            top: 2%;
            left: 10%;
        }
    }
}

.blog-promo-container {
    position: relative;

    @media screen and (min-width: $width-tablet-min) {
        overflow: hidden;
    }
}

.blog-promo-content {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    background-color: $color-jabil-blue;
    background-image: linear-gradient($color-jabil-blue, $color-jabil-deep-blue);
    width: 90%;
    top: 50%;
    left: 5%;
    padding: 12px;

    a {
        cursor: pointer;
        color: $color-white-fa;
        text-align: center;
    }

    .blogListing__blogDate {
        margin-top: 30px;
    }

    @media screen and (min-width: $width-tablet-min) {
        top: 35%;
    }
}

.blog-promo-video {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    background-color: $color-jabil-blue;
    background-image: linear-gradient($color-jabil-blue, $color-jabil-deep-blue);
    width: 100%;
    top: 75%;
    left: 0;
    height: 198px;

    &:before {
        content: "";
        position: absolute;
        height: 30rem;
        width: 30rem;
        top: -10%;
        left: -20%;
        transform: rotate(97deg);
        background-color: $color-jabil-blue;
        background-image: linear-gradient($color-jabil-blue, $color-jabil-deep-blue);
        background-size: cover;
        z-index: 0;

        @media screen and (min-width: $width-tablet-min) {
            top: -10%;
        }

        @media screen and (min-width: $width-desktop-min) {
            top: -25%;
        }

        @media screen and (min-width: $width-standard-screen-min) {
            top: -15%;
        }
    }

    a {
        cursor: pointer;
        color: $color-white-fa;
        text-align: center;
        z-index: 1;
        margin-top: -20px;
    }

    @media screen and (min-width: $width-tablet-min) {
        top: 35%;
    }

    @media screen and (min-width: $width-desktop-min) {
        top: 50%;
    }

    @media screen and (min-width: $width-standard-screen-min) {
        top: 45%;
    }
}

.blog-promo-content > a {
    &:first-child {
        margin: 12px auto 24px;
    }
    &:last-child {
        margin-bottom: 30px;
    }
}

.blog-image-content {
    border: 3px solid $color-jabil-light-blue;
}

.blog-promo-title {
    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 24px;

    &:after {
        content: " ";
        position: absolute;
        left: 15%;
        bottom: -18px;
        width: 70%;
        border-bottom: 3px solid $color-jabil-light-blue;
    }
}

.blog-promo-button {
    cursor: pointer;
    max-height: 40px;
    min-width: 190px;
    align-self: center;
    border-color: $color-jabil-dark-blue;
    background-image: linear-gradient(45deg, $color-jabil-dark-blue, transparent 0);
    padding: 8px 45px;
    font-size: 0.9rem;
    background-color: $color-jabil-blue;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    background-position: 100%;
    background-size: 400%;
    transition: all 0.5s ease-out;
    color: $color-white;

    &:hover {
        border: 1px solid $color-white;
        background: linear-gradient(45deg, $color-white, transparent 0);
    }
}
