@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@use "tabsSearch";
@use "tabsSelect";

.tabs {
    position: relative;
    z-index: 2;
    margin-top: map-get($spacing, 2);
}

.tabs__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    width: 100%;

    @include breakpoint(lg) {
        font-size: 1.2em;
        margin-top: 0;
    }
}

.tabs__title {
    color: $color-grey-cc;
    font-size: 1em;
    font-weight: bold;
    margin: 15px 0;
    text-align: center;
    white-space: nowrap;

    @include breakpoint(lg) {
        font-size: 1.2em;
        text-align: left;
    }
}

.tabs__tabs {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid $color-grey-66;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
    }
}

.tabs__tab {
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px;
    color: white;
    font-weight: 300;
    transition: background-color 300ms ease;
    text-align: inherit;
    margin-bottom: 0;
    cursor: pointer;

    font-size: 14px;
    padding: 0.5em 1em;
    margin-right: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(sm) {
        margin-bottom: 0;
    }

    &:hover {
        background-color: $color-jabil-dark-blue;
        color: white;
    }

    &.active {
        background-color: $color-jabil-darker-blue;
        font-weight: initial;
    }
}

.tabs--filterTabs {
    display: none;

    &.active {
        display: flex;
    }

    @include breakpoint(lg) {
        display: flex;
    }
}

/* stylelint-disable */
.theme--dark.capabilitiesListing,
.blogFilters,
.blogListing {
    .tabs__panel {
        display: none;

        &.active {
            display: flex;
        }
    }

    @include forColors(
            $optOut: (
                "orange"
            )
        )
        using ($color, $value) {
        &.colorTheme--#{$color} {
            .tabs__tab.active,
            .tabs__select select {
                border-color: $value;
            }

            .tabs__toggler {
                cursor: pointer;
                background-color: $color-white-transparent-15;
                border-color: $value;
                padding: 0;
                border-radius: 2px;
                display: block;
                transition: ease-in 0.2s;
                width: 100%;

                a {
                    width: 100%;
                }

                @include breakpoint(lg) {
                    width: auto;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        .colorTheme--#{$color} .tabs__tabActive,
        .colorTheme--#{$color} .blogFilters__link--active {
            background-color: $color-grey-26;
        }
    }
}
/* stylelint-enabled */

.tabs--hasSearch {
    .tabs__container {
        flex: 1;
    }

    .tabs__toggler {
        max-width: 80%;
    }
}

.tabs--hideFilters {
    display: none;
}
