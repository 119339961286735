@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.blogListing__pagination {
    text-align: center;
    margin-bottom: 1em;

    .button {
        cursor: pointer;
    }
}

.blogListing {
    background: #fff;

    .cta {
        background: rgba(234, 235, 236, .6);
        box-shadow: 2px 2px 3px rgba(0,0,0,.05);
    }

    .titleHr:after {
        max-width: 250px;
        width: 100%;
    }

    .cta__image img {
        margin: 0;
    }

    .cta--horizontal .cta__title {
        padding-bottom: 1em;
    }

    .cta__content .button {
        margin-top: 1em;
    }

    .cta__title.titleHr {
        padding-top: 0;
        color: $color-jabil-darker-blue;

        &:hover {
            color: $color-jabil-dark-blue;
        }
    }

    .ctaList--3-up>li {
        background-color: transparent;
        box-shadow: none;
    }
}

.blogListing--center {
    .ctaList {
        margin: 0 auto;
    }
}

.listItem__categoryList {
    li {
        display: inline;
        color: $color-jabil-light-blue;
    }

    li:after {
        content: ", ";
    }

    li:last-child:after {
        content: "";
    }

    a {
        color: $color-jabil-light-blue;
        transition: all ease 0.2s;
    }

    a:hover {
        color: $color-jabil-blue;
    }
}

.blogListing__blogDate {
    display: block;
}

.theme--dark.blogListing {
    background: $color-black;
    position: relative;

    @include forColors(
            $optOut: (
                "orange"
            )
        )
        using ($color, $value) {
        &.colorTheme--#{$color} {
            background: linear-gradient(0deg, rgba($value, 1) 0%, rgba(0, 0, 0, 1) 30%);
        }
    }

    .blogListing__blogDate {
        color: $color-white;
    }
}

.theme--light.blogListing {
    .blogListing__blogDate {
        color: $color-black;
    }
}
