@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.tabsHeader__search {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (min-width: $width-desktop-min) {
        justify-content: flex-end;
    }

    .button {
        width: 10em;

        @media screen and (min-width: $width-desktop-min) {
            width: 15em;
        }
    }
}

.tabsHeader__searchLabel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 1;
}

.tabsHeader__searchInput {
    position: relative;
    width: 3em;
    opacity: 0;
    transition: all ease 0.5s;

    input {
        height: 2.5em;
        border-radius: 6px;
        border: none;
        background: $color-white;
        padding: 0.5em 1em 0.5em 2.7em;
        font-size: 1em;
        width: 100%;
        transition: opacity ease 0.5s;
    }
}

.tabsHeader__searchIcon {
    position: absolute;
    left: 0.7em;
    top: 0.5em;
    width: 1.5em;
    height: 1.5em;
    fill: $color-white;
}

.tabsHeader__searchIconActive {
    fill: $color-black;
}

.tabsHeader--searchActive {
    max-width: 70%;
    margin-left: 1rem;

    @include breakpoint(sm) {
        max-width: 40%;
        margin-left: 0;
    }

    .tabsHeader__searchLabel {
        z-index: 0;
    }
    .tabsHeader__searchInput {
        width: 12em;
        opacity: 1;

        @include breakpoint(md) {
            width: 30em;
        }
    }
}
