@use "../variables" as *;
@use "../mixins/color" as *;

.ctaBar {
    text-align: center;
}

.ctaBar__wrap {
    padding: 3em 0;
}

.ctaBar .titleHr:after {
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.ctaBar__title {
    font-size: 2rem;
    margin-bottom: 1em;
    padding-bottom: 2em;
    display: inline-block;

    &.ctaBar__title--hasSubtitle {
        margin-bottom: 5px;
        padding-bottom: 0;
    }
}

.ctaBar__subtitle {
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2em;
    width: fit-content;
}

.ctaBar_links {
    display: block;

    *,
    :after,
    :before {
        box-sizing: initial;
    }

    @media only screen and (max-width: 360px) {
        *,
        :after,
        :before {
            box-sizing: border-box;
        }
    }
}

.ctaBar_links > li {
    display: inline-block;
    margin: 0 1em 1em;
}

.ctaBar_links .button {
    min-width: 250px;
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} {
        &.ctaBar .titleHr:after {
            border-bottom-color: $value;
        }
    }

    .ctaBar--#{$color} {
        background: $value;
    }
}

.theme--dark .ctaBar {
    color: $color-white;
}

.theme--light .ctaBar {
    color: $color-black;
}
